<template>
  <div>
    <div class="load" v-if="isLoading">
      <div class="loadImgs">
        <img src="images/loading-logo.png" alt="" />
        <img src="images/loading.png" class="loadImg" alt="" />
      </div>
    </div>


    <!-- yearCartCheckPage -->
    <div class="yearCartCheckPage">
        <!-- yearCardcontainer -->
        <div class="yearCardcontainer">


            <!-- yearCartBox -->
            <div class="yearCartBox">

                <div class="yearCartRow" ref="YillikPaketler">
                    <div class="yearCartCol">
                        <div class="yearCartColImg" style="margin:0"><img src="/images/primeFly_1.png" alt=""  style="width: 349px;"> </div>
                        <div class="yearCartStepperTitle">PrimeFLY</div>
                        <div class="yearCartStepperText">
                            <p>
                                PrimeFLY'ın ayrıcalıklı dünyasında eğlenceyi zirveye taşımaya hazır mısınız?
                              </p><br>
                               <p>
                                PrimeFLY kartla <strong>1.999 TL</strong>'ye 365 gün, günde maksimum 2 saat uçuş hakkına sahip oluyorsunuz!
                               </p>
                               
                                </div>
                        <div class="yearCartStepper" data-price="1999">
                            <button class="decrement" @click="decrementValue('PrimeFLY')"></button>
                            <input type="text" class="value" value="0" readonly="readonly" min="0" max="10" v-model="this.PrimeFlyCount">
                            <button class="increment" @click="incrementValue('PrimeFLY')"></button>
                        </div>
                    </div>
                    <div class="yearCartCol">
                        <div class="yearCartColImg"> <img src="/images/ecoFly_1.png" alt="" style="width: 353px;"> </div>
                        <div class="yearCartStepperTitle">EcoFLY</div>
                        <div class="yearCartStepperText">

                            <p>
                                EcoFLY kart ise; özel günler ve hafta sonları hariç günlerde kullanılabilecek şekilde tasarlandı. <br>
                                Hemde sadece 3 giriş fiyatına <strong>1.499 TL</strong>'ye sahip olunabilen EcoFLY kart ile uçuş keyfiniz zirveye çıkıyor!
                              </p>
                              

                        </div>
                        <div class="yearCartStepper" data-price="1499">
                            <button class="decrement" @click="decrementValue('EcoFLY')"></button>
                            <input type="text" class="value" value="0" readonly="readonly" min="0" max="10" v-model="this.EcoFlyCount">
                            <button class="increment" @click="incrementValue('EcoFLY')"></button>
                        </div>
                    </div>
                </div>

                <div class="yearCartInfo">
                    <p>
                        Lütfen hangi tip karttan kaç adet alacağınızı belirtin! <br />
                        Sınırlı sayıda olan kartlarımız <strong>sadece 7 yaş ve üzeri </strong>misafirlerimiz için geçerlidir.
                    </p>
                </div>

                <div class="yearCartPrice">
                    <span class="yearTotalPrice">Toplam Fiyat :</span>
                    <div class="yearPrice"> <div class="price" v-text="this.PackageTotalPrice"></div> TL </div>
                    <button class="yearBuyBtn" @click="scrollclick('bilgiler')">SATIN AL</button>
                </div>

            </div>
            <!-- /yearCartBox -->

      

            <!-- yearCartBox -->
            <div class="yearCartBox" ref="bilgiler">
                <div class="errorTextBox" v-if="this.errorText">
                    <ul>
                        <li v-if="this.errorfirstName">İsim alanı boş geçilemez</li>
                        <li v-if="this.errorlastName">Soyisim alanı boş geçilemez</li>
                        <li v-if="this.errorphoneNumber">Telefon Numarası alanı hatalı</li>

                        <li v-if="this.erroremailAddr">Email Adresi alanı hatalı</li>

                        <li v-if="this.errorgender">Cinsiyet alanı boş geçilemez</li>

                        <li v-if="this.errorbirthdate">Doğum Tarihi alanı hatalı</li>

                        <li v-if="this.kurumsal && this.errorinvoice">
                            Fatura Ünvan alanı boş geçilemez
                        </li>

                        <li v-if="!this.kurumsal && this.idenNumber.length !== 11 ">
                            TC Kimlik No alanı 11 karakter olmalıdır
                        </li>
                        <li v-if="this.kurumsal && this.idenNumber.length !== 10 ">
                            Vergi Numarası alanı 10 karakter olmalıdır
                        </li>

                        <li v-if="this.erroraddress">Adres alanı boş geçilemez</li>

                        <li v-if="this.errordeliveryKonum">Şube alanı boş geçilemez</li>

                        <li v-if="this.errorccfullname">
                            Kredi Kartı İsim Soyisim alanı boş geçilemez
                        </li>
                        <li v-if="this.errorCartNo">
                            Kart Numarası alanı hatalı
                        </li>
                        <li v-if="this.errorccMonth || this.errorccYear">
                            Kredi Kartı Tarih alanı boş geçilemez
                        </li>
                        <li v-if="this.errorcvv">
                            Kredi Kartı CVV alanı boş geçilemez
                        </li>


                    </ul>
                </div>


                <div class="yearCartBoxHeadTitle">Müşteri Bilgileri</div>
                <form class="yearCartForm">
                    <ul>
                        <li>
                            <!--<input type="text" name="firstName" id="firstName" class="yearCartInput" value="Test isim" placeholder="">-->

                            <input
                            type="text"
                            v-model="firstName"
                            class="yearCartInput"
                            :class="{ error: this.errorfirstName }"
                            @focus="errorSil('firstName')"
                          />


                            <label for="firstName" class="yearCartLabel">İsim</label>
                        </li>
                        <li>
                            <!--<input type="text" name="lastName" id="lastName" class="yearCartInput" value="Test soyisim" placeholder="">-->
                            <input
                            type="text"
                            v-model="lastName"
                            class="yearCartInput"
                            :class="{ error: this.errorlastName }"
                            @focus="errorSil('lastName')"
                          />
                            <label for="lastName" class="yearCartLabel">Soyisim</label>
                        </li>
                        <li>
                            <!--<input type="tel" name="phone" id="phone" class="yearCartInput" value="0536 000 00 00" placeholder="">-->

                            <input
                            type="text"
                            v-model="phoneNumber"
                            class="yearCartInput"
                            v-mask="'+90 (###) ### ## ##'"
                            :class="{ error: this.errorphoneNumber }"
                            @focus="errorSil('phoneNumber')"
                          />

                            <label for="phone" class="yearCartLabel">Telefon Numarası</label>
                        </li>
                        <li>
                            <!--<input type="email" name="email" id="email" class="yearCartInput" value="test@test.com" placeholder="">-->


                            <input
                            type="text"
                            v-model="emailAddr"
                            class="yearCartInput"
                            :class="{ error: this.erroremailAddr }"
                            @focus="errorSil('emailAddr')"
                            v-on:keyup="$event.target.value = $event.target.value.replace(/\s+/g, '')"
                          />

                            <label for="email" class="yearCartLabel">E-mail Adresi</label>
                        </li>
                        <li>
                            <select name="gender" id="gender" class="yearCartInput"
                             v-model="gender"
                             :class="{ error: this.errorgender }"
                             @focus="errorSil('gender')"
                             >
                                <option value="1">Erkek</option>
                                <option value="2">Kadın</option>
                            </select>
                            <label for="gender" class="yearCartLabel">Cinsiyet</label>
                        </li>
                        <li>

                            <input
                            type="text"
                            v-model="birthdate"
                            class="yearCartInput"
                            :class="{ error: this.errorbirthdate }"
                            @focus="errorSil('birthdate')"
                            v-mask="'##-##-####'"

                          />
<!--

                        <div class="datepickerStyleContainer yearCartInput"
                            :class="{ error: this.errorbirthdate }"
                            @focus="errorSil('birthdate')"

                            >    
                                               
                                <datepicker
                                v-model="birthdate"
                                type="date"
                                mode="single"
                                :format="customFormatter"
                                :language="tr"
                                ref="datepicker"
                                @focusin.native="errorSil('birthdate')"

                                />
                        
                            </div>
--->

                            <label for="dateBirth" class="yearCartLabel">Doğum Tarihi (Gün - Ay - Yıl)</label>
                        </li>
                        <li>
                            <!--<input type="text" name="invoice" id="invoice" class="yearCartInput" value="Test" placeholder="">-->

                            <input
                            type="text"
                            v-model="invoice"
                            class="yearCartInput"
                            :class="{ error: this.errorinvoice }"
                            @focus="errorSil('invoice')"
                          />



                            <label for="invoice" class="yearCartLabel">Fatura Ünvan</label>

                            <div class="yearCartCheckbox">
                            <!--                                
                                <input type="checkbox" id="institutional" name="institutional" value="kurumsal" v-model="kurumsal" />
                                <label for="institutional">Kurumsal</label>
                            -->
                                <div class="fly-switch">
                                 
                                  <label class="switch" for="institutional">
                                    <input type="checkbox" id="institutional" name="institutional" value="kurumsal" v-model="kurumsal">
                                    <span class="slider round"></span>
                                    <font>Kurumsal</font>
                                  </label>

                                  </div>
                            </div>

                        </li>
                        <li>
                            <!--
                            <input type="text" name="idenNumber" id="idenNumber" class="yearCartInput"
                                value="11111111110" placeholder="">
                        -->


                                <input
                                type="text"
                                v-model="idenNumber"
                                class="yearCartInput"
                                maxlength="11" 
                                :class="{ error: this.erroridenNumber }"
                                @focus="errorSil('idenNumber')"
                              />



                                <label for="idenNumber" class="yearCartLabel">TC Kimlik No / Vergi Numarası </label>
                        </li>
                        <li>
                            <textarea name="address" id="address"

                                class="yearCartInput yearCartTextarea" 
                                v-model="address"
                                :class="{ error: this.erroraddress }"
                                @focus="errorSil('address')"
                                
                                ></textarea>
                                <label for="address" class="yearCartLabel"> Adres </label>
                        </li>
                        <li v-if="this.isSetKonum">
                            <select name="deliveryKonum" id="deliveryKonum" class="yearCartInput"
                             v-model="deliveryKonum"
                             :class="{ error: this.errordeliveryKonum }"
                             @focus="errorSil('deliveryKonum')"
                             >
                                <option value="0">Lütfen şube seçiniz</option>
                                <option value="1">Mall Of İstanbul</option>
                                <option value="3">İstiklal AVM</option>
                                <option value="4">Nissara AVM Nevşehir</option>
                                <option value="5">Torium AVM</option>
                                <option value="8">Lens Yaşam AVM</option>
                            </select>
                            <label for="deliveryKonum" class="yearCartLabel">Teslim alacağınız şube</label>
                        </li>
                    </ul>
                </form>
            </div>
            <!-- /yearCartBox -->


            <!-- yearCartBox -->
            <div class="yearCartBox">
                <div class="yearCartBoxHeadTitle">Ödeme Yap</div>
                <div class="yearCartCheckout">
                    <form class="yearCartForm">
                        <ul>
                            <li>
                                <input type="text" name="ccfullName" id="ccfullName" class="yearCartInput js-card-holder" placeholder=""
                                :class="{ error: this.errorccfullname }"
                                @focus="errorSil('ccfullname')"
                                v-model="ccfullname"
                                >
                                <label for="ccfullName" class="yearCartLabel">İsim Soyisim</label>
                            </li>
                            <li>
                                <input type="text" name="CartNo" id="CartNo" class="yearCartInput js-input-cart-number"
                                    maxlength="19" placeholder=""
                                    
                                    v-mask="'#### #### #### ####'"
                                    :class="{ error: this.errorCartNo }"
                                    @focus="errorSil('CartNo')"
                                    @blur="loadInstallments" 
                                    v-model="CartNo"

                                    >
                                    <label for="CartNo" class="yearCartLabel">Kart Numarası</label>
                            </li>
                            <li class="w100">
                                <div class="yearCartSelectRow">
                                    <div class="yearCartSelectBox">
                                        <select name="card-expiration-month" id="card-expiration-month"
                                            class="yearCartInput js-card-expiration-month"
                                            :class="{ error: this.errorccMonth }"
                                            @focus="errorSil('ccMonth')"
                                            v-model="ccMonth"
                                            >
                                            <option value="">Ay</option>
                                            <option value="01">01</option>
                                            <option value="02">02</option>
                                            <option value="03">03</option>
                                            <option value="04">04</option>
                                            <option value="05">05</option>
                                            <option value="06">06</option>
                                            <option value="07">07</option>
                                            <option value="08">08</option>
                                            <option value="09">09</option>
                                            <option value="10">10</option>
                                            <option value="11">11</option>
                                            <option value="12">12</option>
                                        </select>
                                    </div>

                                    <div class="yearCartSelectBox">
                                        <select name="card-expiration-year" id="card-expiration-year"
                                            class="yearCartInput js-card-expiration-year"
                                            :class="{ error: this.errorccYear }"
                                            @focus="errorSil('ccYear')"
                                            v-model="ccYear" 
                                            >
                                            <option value="">Yıl</option>
                                            <option value="2024">2024</option>
                                            <option value="2025">2025</option>
                                            <option value="2026">2026</option>
                                            <option value="2027">2027</option>
                                            <option value="2028">2028</option>
                                            <option value="2029">2029</option>
                                            <option value="2030">2030</option>
                                            <option value="2031">2031</option>
                                            <option value="2032">2032</option>
                                            <option value="2033">2033</option>
                                        </select>
                                    </div>

                                    <div class="yearCartSelectBox">
                                        <input type="text" name="cvv" placeholder=""
                                            class="yearCartInput js-card-ccv" id="Cvv" maxlength="3"
                                            :class="{ error: this.errorcvv }"
                                            @focus="errorSil('cvv')"
                                            v-model="cvv" 
                                            
                                            >
                                            <label for="Cvv" class="yearCartLabel">Cvv</label>
                                    </div>


                                </div>
                            </li>

                            <li class="w100" v-if="installmentOptions.length > 0">
                              
                                <div class="yearCartBoxHeadTitle">Taksit Seçenekleri</div>
                                <div class="radioItem" v-for="(option, index) in installmentOptions" :key="index" v-if="index === 0">
                                  <label :for="`radioItem-${index}`" >
                                    
                                    <input :id="`radioItem-${index}`" type="radio" name="taksitSecimi" v-model="taksitSecimi" :value="option.installment" :checked="index == 0"  />
                                    {{ option.installment }} Taksit Seçeneği ({{ option.amount }} TL)
                                  </label>
                                </div>
                              </li>
                           
                            <li class="w100">
                                <a style="cursor:pointer" class="yearCartSubmit" data-modal="#yearCheckoutModal" @click="validateForm">Ödeme Yap</a>
                            </li>
                            <li>

                                <div class="yearCartPrice">
                                    <span class="yearTotalPrice">Toplam Fiyat :</span>
                                    <div class="yearPrice"> <div class="price" v-text="this.PackageTotalPrice"></div> TL </div>
                                </div>
                

                            </li>

                        </ul>
                    </form>


                    <div class="credit-card-box">
                        <div class="flip">
                            <div class="front">
                                <div class="chip"><img src="/images/chip.png"></div>
                                <div class="number" v-text="this.CartNo"></div>

                                <div class="card-holder">
                                    <label>KART SAHİBİ</label>
                                    <div class="card-name" v-text="this.ccfullname"></div>
                                </div>
                                <div class="card-expiration-date">
                                    <label>VALID THRU</label>
                                    <div>{{ this.ccMonth }} / {{ this.ccYear }}</div>
                                </div>
                            </div>
                            <div class="back">
                                <div class="strip"></div>
                                <div class="ccv">
                                    <label>CCV</label>
                                    <div>{{ this.cvv }}</div>
                                </div>
                            </div>
                        </div>
                    </div>



                </div>
            </div>
            <!-- /yearCartBox -->







        </div>
        <!-- /yearCardcontainer -->
    </div>
    <!-- /yearCartCheckPage -->



    <Footer />
     <!-- <div class="odemePopup" :class="{ active: popActive === true }">
      <div class="popBg" @click="popKapat"></div>
      <div class="popContent">
        <div class="popClose" @click="popKapat">X</div>
        <iframe
          class="payFrame"
          :src="payurl"
          width="100%"
          height="100%"
        ></iframe>
      </div>
    </div>-->
  </div>
</template>

<script>

import axios from "axios";
import Datepicker from 'vuejs-datepicker'
import moment from 'moment'
import { tr } from 'vuejs-datepicker/dist/locale'

import Footer from "./Footer";
import { error } from 'jquery';
export default {
  name: "yillik-kart-odeme",
  components: { Footer,Datepicker },

  data() {
    return {        
        firstName:"",
        lastName:'',
        phoneNumber:'',
        emailAddr:'',
        gender:'',
        birthdate:'',
        kurumsal:false,
        invoice:'',
        idenNumber:'',
        address:'',

        ccfullname:'',
        //CartNo:'',
        ccMonth:'',
        ccYear:'',
        cvv:'',

        errorfirstName:false,
        errorlastName:false,
        errorphoneNumber:false,
        erroremailAddr:false,
        errorgender:false,
        errorbirthdate:false,
        errorinvoice:false,
        erroridenNumber:false,
        erroraddress:false,
        errorccfullname:false,
        //errorCartNo:false,
        errorccMonth:false,
        errorccYear:false,
        errorcvv:false,
        errordeliveryKonum:false,

        errorText:false,

        tr: tr,
        
        /**********/


      CartNo: '',
      taksitSecimi: '1',
      installmentOptions: [],
      errorCartNo: false,
      PrimeFlyPrice:1999,
      EcoFlyPrice:1499,
      PrimeFlyCount:0,
      EcoFlyCount: 0,
      popActive: false,
      sozlesmeModal: false,
      payurl: "",
      PackageTotalPrice:0,
      channelCode: '',
      deliveryKonum:localStorage.konumId?localStorage.konumId:0,
      isSetKonum:false
    }
  },
  methods: {
    checkBulkTickets() {},
    setSelectedPackage(bulkTicket) {},

    customFormatter(date) {
      return moment(date).format('DD.MM.YYYY')
    },        
    scrollclick(refName) {


            console.log('1',refName)
            console.log('2',this.$refs[refName].offsetTop)
            var element = this.$refs[refName];
            var top = element.offsetTop;
            window.scrollTo(0, top);


    },
    validateTextInfo(){

    },
    /* form */
    ageCheck(userDate) {

        var date = moment(userDate, 'DD-MM-YYYY')
        var years = moment().diff(date.format('YYYY-MM-DD'), 'years',false);

        if (years >= 7) {
            return true
        } else {
            alert('Yıllık kartlar sadece 7 yaş ve üzeri misafirlerimiz adına satın alınabilir')

            return false
        }
    },

    validateForm(){


        //if(this.deliveryKonum === typeof undefined) alert("TEST")
        console.log("delivery", this.deliveryKonum)
        //console.log('this.emailAddr', this.emailAddr, this.emailAddr)



        
        if (this.firstName === '' || this.firstName.length < 2) {
            this.errorfirstName = true; 
        }
        if (this.lastName === '' || this.lastName.length < 2) {
            this.errorlastName = true
        }
        if (this.phoneNumber.length < 19) {
            this.errorphoneNumber = true
        }    
        if(!this.validateEmail(this.emailAddr)) {
            this.erroremailAddr = true
        }
        if (this.gender === '') {
            this.errorgender = true
        } 

        if(this.birthdate === '') {
            this.errorbirthdate = true
        } else if(this.ageCheck(this.birthdate) === false) {
            this.errorbirthdate = true
        }

        if ((this.kurumsal) && (this.invoice === '')) {
            this.errorinvoice = true
        }

        if((!this.kurumsal) && (this.idenNumber.length !== 11)) {
            this.erroridenNumber = true
        }
        if((this.kurumsal) && (this.idenNumber.length !== 10)) {
            this.erroridenNumber = true
        }

        if((this.kurumsal) && (this.address === '' || this.address.length < 10)) {
            this.erroraddress = true
        }

        
        if (this.ccfullname === '' || this.ccfullname.length < 2) {
            this.errorccfullname = true
        }


        if(this.CartNo.length < 19) {
            this.errorCartNo = true
        }

        if(this.ccMonth === '') {
            this.errorccMonth = true
        } 

        if(this.ccYear === '') {
            this.errorccYear= true
        }
        
        if(this.cvv === '') {
            this.errorcvv= true
        }
        if(this.isSetKonum && (this.deliveryKonum === 0 || this.deliveryKonum === typeof undefined)) {
            this.errordeliveryKonum= true
        }
        

/*
console.log('FULL',
this.PrimeFlyCount, this.EcoFlyCount,
this.errorfirstName, this.errorlastName, this.errorphoneNumber,  this.erroremailAddr , 
this.errorgender ,  this.errorbirthdate , this.errorinvoice , this.erroridenNumber , 
this.erroraddress, 
this.errorccfullname , this.errorCartNo , 
this.errorccMonth , this.errorccYear , this.errorcvv, '|', this.kurumsal, this.errorinvoice, this.erroraddress
)
*/
if(this.PrimeFlyCount <= 0 && this.EcoFlyCount <= 0) {
    this.scrollclick('YillikPaketler')
}
else if(
    (this.PrimeFlyCount > 0 || this.EcoFlyCount > 0) &&
    (this.errorfirstName === false && this.errorlastName === false &&  this.errorphoneNumber === false &&  this.erroremailAddr === false && 
    this.errorgender === false &&  this.errorbirthdate === false && this.erroridenNumber === false && 
    this.errorccfullname === false && this.errorCartNo === false && 
    this.errorccMonth === false && this.errorccYear === false && this.errorcvv === false && this.errordeliveryKonum === false)
) {

        
        if(!this.kurumsal) {
            setTimeout(() => {
                this.formSubmit();
                }, 1000);

        }
        else if((this.kurumsal) && (this.errorinvoice === false && this.erroraddress === false)) {
            setTimeout(() => {
                this.formSubmit();
                }, 1000);

        } else {
            //alert('NOSUBMIT');
        }

    } else {
        console.log('form error text')
        this.errorText = true;
        this.scrollclick('bilgiler');

    }

    },    
    cardClear() {
      localStorage.cardAdSoyad = "";
      localStorage.cardNo = "";
      localStorage.cardAy = 0;
      localStorage.cardYil = 0;
      localStorage.cardCvv = "";
      localStorage.installment = 0;
      
    },
    popKapat() {
      this.popActive = false;
      this.payurl = "";
    },
    formSubmit(){
        console.log('submit start');
        var thisComponent = this;
        //thisComponent.validateForm();

        if(this.deliveryKonum <= 0 || this.deliveryKonum === typeof undefined) {
            this.errordeliveryKonum = true;
            return false;
        }


        if(this.isSetKonum) { localStorage.konumId = this.deliveryKonum;}
        
            

        const formData = {
        territoriId:  parseInt(localStorage.konumId),
        PrimeFlyCount: this.PrimeFlyCount,
        EcoFlyCount:  this.EcoFlyCount,
        customer: {
            Name: this.firstName,
            Surname: this.lastName,
            CustomerGsm: this.phoneNumber.replace(/[^0-9]/g, '').substring(2),
            Email: this.emailAddr,
            Gender: this.gender,
            BirthDate: moment(this.birthdate, "DD-MM-YYYY").format("YYYY-MM-DD"),
            CompanyName: this.invoice,
            Tcno: this.idenNumber,
            Address: this.address,
        },
        //ChannelCode:this.channelCode 
        ChannelCode: localStorage.getItem('channelCode')
      };
        console.log('submit object',formData);

      localStorage.cardAdSoyad = this.ccfullname;
      localStorage.cardNo = this.CartNo;
      localStorage.cardAy = this.ccMonth;
      localStorage.cardYil = this.ccYear;
      localStorage.cardCvv = this.cvv;
      localStorage.installment = this.taksitSecimi;
      localStorage.email= this.emailAddr;
      localStorage.tcNo=this.idenNumber;

      console.log('localstorage is ok');
      console.log(formData)
      axios({
          url: `${process.env.VUE_APP_ENV_SERVER}/Services/FlyzoneCrm/B2CServices/SaveOrderWebYearlyCard`,
          method: "post",
          headers: { "Content-Type": "application/json" },
          data: JSON.stringify(formData),
        }).then((response2) => {
          localStorage.setItem("data", response2.data);
          console.log("HELLO");

          window.addEventListener("message", function (e) {
            if (e.data.event === "warranty-transaction-success") {
              console.log(e.data);
              thisComponent.cardClear();
              if (e.data.data === "1") {
                
                thisComponent.popKapat();
                thisComponent.$router.push("/yillik-kart-odeme-tesekkur");
                console.log("popupklapat", "status");
              }
            } else {
              console.log('ERROR: e.data.event != "warranty-transaction-success"');
              console.log(response2)
              //thisComponent.popKapat();
            }
          });
          localStorage.payProcess = response2.data.payProcess;
          thisComponent.payurl = "PostKartIyz.html";
        });

        thisComponent.popActive = true;
    
    
    },

    errorSil(name){ 


        console.log('errorSil', name)

        if(name === 'firstName') this.errorfirstName=false;
        if(name === 'lastName') this.errorlastName=false;
        if(name === 'phoneNumber') this.errorphoneNumber=false;
        if(name === 'emailAddr') this.erroremailAddr=false;
        if(name === 'gender') this.errorgender=false;
        if(name === 'birthdate') this.errorbirthdate=false;
        if(name === 'invoice') this.errorinvoice=false;
        if(name === 'idenNumber') this.erroridenNumber=false;
        if(name === 'address') this.erroraddress=false;

        if(name === 'ccfullname') this.errorccfullname=false;
        if(name === 'CartNo') this.errorCartNo=false;
        if(name === 'ccMonth') this.errorccMonth=false;
        if(name === 'ccYear') this.errorccYear=false;
        if(name === 'cvv') this.errorcvv=false;
        if(name === 'deliveryKonum') this.errordeliveryKonum=false;
        

        


    
    },
    validateEmail(email){
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    },
    loadInstallments() {
      if (this.CartNo.length < 6) {
        alert('Kart numarası en az 6 hane olmalıdır.');
        return;
      }

      const data = {
        TotalAmount: this.PackageTotalPrice,  // Bu değer gerektiği gibi ayarlanabilir.
        CardNumber: this.CartNo.replace(/\s+/g, '')
      };

      axios({
          url:`${process.env.VUE_APP_ENV_PAYMENT_BASE}/payment/GetIyzicoInstallments`,
          method: "post",
          headers: { "Content-Type": "application/json" },
          data: JSON.stringify(data),
      }).then(response => {
        this.installmentOptions = response.data.map(installment => ({
          amount: installment.amount,
          installment: installment.installment,
          totalAmount: installment.totalAmount
        }));
      }).catch(error => {
        console.error('API isteği sırasında bir hata oluştu:', error);
      });
    },


        /* stepper input */


        incrementValue(section) {

            if (section === 'PrimeFLY' && this.PrimeFlyCount < 10) this.PrimeFlyCount++;
            if (section === 'EcoFLY' && this.EcoFlyCount < 10) this.EcoFlyCount++;

            this.updateTotalPrice();

            //console.log('incrementValue', button)

            /*
            var stepper = button.parentElement;
            var valueInput = stepper.querySelector('.value');
            var value = parseInt(valueInput.value, 10);
            if (value < 10) {
                valueInput.value = value + 1;
                updateTotalPrice();
            }
            */
        },

        decrementValue(section) {
            if (section === 'PrimeFLY' && this.PrimeFlyCount > 0) this.PrimeFlyCount--;
            if (section === 'EcoFLY' && this.EcoFlyCount > 0) this.EcoFlyCount--;

            this.updateTotalPrice();
            /*
            var stepper = button.parentElement;
            var valueInput = stepper.querySelector('.value');
            var value = parseInt(valueInput.value, 10);
            if (value > 0) {
                valueInput.value = value - 1;
                updateTotalPrice();
            }
            */
        },

        updateTotalPrice() {
            this.PackageTotalPrice = (this.PrimeFlyPrice * this.PrimeFlyCount) + (this.EcoFlyPrice * this.EcoFlyCount);
/*
            var total = 0;
            var stepperElements = document.querySelectorAll('.yearCartStepper');
            stepperElements.forEach(function(stepperElement) {
                var pricePerItem = parseInt(stepperElement.getAttribute('data-price'), 10);
                var quantity = parseInt(stepperElement.querySelector('.value').value, 10);
                total += pricePerItem * quantity;
            });
            
            var totalPriceElements = document.querySelectorAll('.price');
            totalPriceElements.forEach(function(element) {
                element.textContent = total;           
            });
*/         
        },
        /* stepper input */


  },
  computed: {
    bulkTicket() {
      return this.$root.bulkTicket;
    },
  },
  created() {
    this.$root.sepetBos = false;
    this.isLoading = false;
    this.$root.menuActive1 = false;
    this.$root.menuLink1 = true;
    this.$root.menuActive2 = false;
    this.$root.menuLink2 = true;
    this.$root.menuActive3 = false;
    this.$root.menuLink3 = true;
    this.$root.menuActive4 = false;
    this.$root.menuLink4 = true;
    this.$root.menuActive5 = false;
    this.$root.menuLink5 = true;
    this.$root.menuActive6 = true;
    this.$root.menuLink6 = false;
  },
  mounted() {

    //console.log('this.$route.query.cc',this.$route.query.cc)

    this.channelCode = this.$route.query.cc || '';
    if(this.channelCode === 'NOLCBFFVU3G5AWJB33KR90ARWG32MR' || this.channelCode === null || this.channelCode === '' || this.channelCode === typeof undefined){ 
        this.isSetKonum = true;
    }

    if(this.channelCode !== '' && this.channelCode !== null && this.channelCode !== typeof undefined) {
        window.localStorage.setItem('channelCode', this.channelCode)
    }
    
    
    document.body.style.backgroundColor = "black";
    this.checkBulkTickets()
  },
  beforeDestroy() {
    document.body.style.backgroundColor = "";
  },
};
</script>
<style scoped>


</style>
